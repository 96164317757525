import {
  BorderStyle,
  AlignmentOptions,
  PaymentMethod,
} from '../../types/types';
import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { SelectedPaymentOption } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type ISettingsParams = {
  titlesAlignment: AlignmentOptions;
  fieldsBorderStyle: BorderStyle;
  stretchButtonToFullWidth: SettingsParamType.Boolean;
  formTitle: SettingsParamType.String;
  selectPaymentTitle: SettingsParamType.String;
  pricingPlanText: SettingsParamType.String;
  singleSessionText: SettingsParamType.String;
  selectPaymentTypeLabel: SettingsParamType.String;
  payOnlineText: SettingsParamType.String;
  payOfflineText: SettingsParamType.String;
  addToCartText: SettingsParamType.String;
  bookNowButtonText: SettingsParamType.String;
  checkoutText: SettingsParamType.String;
  offlinePaymentButtonText: SettingsParamType.String;
  onlinePaymentButtonText: SettingsParamType.String;
  requestBookingButtonText: SettingsParamType.String;
  chooseAPlanText: SettingsParamType.String;
  summarySectionTitle: SettingsParamType.String;
  videoConferenceTitle: SettingsParamType.String;
  summaryPaymentSectionTitle: SettingsParamType.String;
  videoConferenceBadgeVisibility: SettingsParamType.Boolean;
  defaultPaymentMethod: PaymentMethod;
  defaultPaymentTime: SelectedPaymentOption;
};

export default createSettingsParams<ISettingsParams>({
  titlesAlignment: {
    getDefaultValue: ({ isRTL }): AlignmentOptions =>
      isRTL ? AlignmentOptions.RIGHT : AlignmentOptions.LEFT,
  },
  fieldsBorderStyle: {
    getDefaultValue: () => BorderStyle.BOX,
  },
  stretchButtonToFullWidth: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  formTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.form.fill-out-your-details.title'),
  },
  selectPaymentTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.booking-details.form.payment.title'),
  },
  pricingPlanText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.payment-options.buy-a-pricing-plan.label'),
  },
  singleSessionText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.payment-options.pay-for-single-session.label'),
  },
  selectPaymentTypeLabel: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.payment-options.when-do-you-want-to-pay.label'),
  },
  payOnlineText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.payment-options.pay-online.label'),
  },
  payOfflineText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.payment-options.pay-offline.label'),
  },
  addToCartText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.text.form.button.add-to-cart-placeholder'),
  },
  bookNowButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.settings.text.from.button.book-now.placeholder'),
  },
  checkoutText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.summary.cta.checkout.label'),
  },
  offlinePaymentButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.booking-details.summary.cta.book.label'),
  },
  onlinePaymentButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.summary.cta.pay-now.label'),
  },
  requestBookingButtonText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.summary.cta.request-to-book.label'),
  },
  chooseAPlanText: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) =>
      t('app.booking-details.pricing-plans.choose-a-plan-label'),
  },
  summarySectionTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.booking-details.booking-summary.text'),
  },
  summaryPaymentSectionTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.booking-details.payment-summary.title'),
  },
  videoConferenceTitle: {
    type: SettingsParamType.String,
    getDefaultValue: ({ t }) => t('app.booking-details.video-conference.text'),
  },
  videoConferenceBadgeVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  defaultPaymentMethod: {
    getDefaultValue: () => PaymentMethod.MEMBERSHIP,
  },
  defaultPaymentTime: {
    getDefaultValue: () => SelectedPaymentOption.ONLINE,
  },
});
