import React from 'react';
import VideoConferenceBadge from '../../VideoConferenceBadge/VideoConferenceBadge';
import Text, { TextType } from '../../../Text/Text';
import DateAndTime from '../../DateAndTime/DateAndTime';
import Duration from '../../Duration/Duration';
import StaffMembers from '../../StaffMembers/StaffMembers';
import Location from '../../Location/Location';
import { SingleSlotDetailsDataHook } from './dataHooks';
import { TagName } from '../../../../../utils/tagName.const';
import { FormNestedSlot } from '../../../../../types/formSelectedSlots';
import { classes } from './SingleSlotDetails.st.css';
import { SlotService } from '../../../../../utils/state/types';
import PriceLine from './PriceLine/PriceLine';

export type SingleSlotDetailsProps = {
  slotService: SlotService;
  slot: FormNestedSlot;
  dateRegionalSettingsLocale: string;
  totalNumberOfParticipants?: number;
  isSingleService: boolean;
  isInPreviewMode?: boolean;
};

const SingleSlotDetails: React.FC<SingleSlotDetailsProps> = ({
  slotService,
  slot,
  dateRegionalSettingsLocale,
  totalNumberOfParticipants,
  isSingleService,
  isInPreviewMode,
}) => {
  const { service, selectedPaymentOption } = slotService;
  const dynamicPriceTotalNumberOfParticipants =
    slotService.dynamicPriceInfo?.selectedVariants?.reduce(
      (acc, next) => (acc += next.numberOfParticipants || 0),
      0,
    );

  return (
    <div className={classes.root}>
      <Text
        type={TextType.Primary}
        data-hook={SingleSlotDetailsDataHook.SERVICE_NAME}
        tagName={TagName.Paragraph}
      >
        {service.name}
      </Text>
      <DateAndTime
        totalNumberOfSessions={service.totalNumberOfSessions}
        slot={slot}
        service={service}
        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
      />
      <VideoConferenceBadge
        includesConferenceOption={service.includesConferenceOption}
      />
      <Location location={service.location} />
      <StaffMembers service={service || []} />
      <Duration
        slot={slot}
        isInPreviewMode={isInPreviewMode}
        totalNumberOfSessions={service.totalNumberOfSessions}
      />
      {!isSingleService &&
        (slotService.dynamicPriceInfo?.selectedVariants?.length ? (
          slotService.dynamicPriceInfo.selectedVariants.map((variant) => (
            <PriceLine
              key={variant?.choices?.[0].optionId}
              dataHook={SingleSlotDetailsDataHook.PRICE_LINE}
              selectedPaymentOption={selectedPaymentOption}
              dateRegionalSettingsLocale={dateRegionalSettingsLocale}
              slotService={slotService}
              selectedVariant={variant}
              totalNumberOfParticipants={dynamicPriceTotalNumberOfParticipants}
            />
          ))
        ) : (
          <PriceLine
            dataHook={SingleSlotDetailsDataHook.PRICE_LINE}
            selectedPaymentOption={selectedPaymentOption}
            dateRegionalSettingsLocale={dateRegionalSettingsLocale}
            slotService={slotService}
            totalNumberOfParticipants={totalNumberOfParticipants}
          />
        ))}
    </div>
  );
};

export default SingleSlotDetails;
