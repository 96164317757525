import React from 'react';

export type FormComponentContextType = { isCartConflictError?: boolean };

const FormComponentContext = React.createContext<FormComponentContextType>(
  {} as FormComponentContextType,
);
export const FormComponentContextProvider = FormComponentContext.Provider;

export const useFormComponentContext = () =>
  React.useContext(FormComponentContext);
