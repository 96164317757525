import React from 'react';
import Text, { TextType } from '../../Text/Text';
import { CollapseFormDataHooks } from './dataHooks';
import {
  SectionNotification,
  SectionNotificationSize,
  SectionNotificationType,
} from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './CollapseForm.st.css';
import { ServiceData } from '../../../../utils/state/types';

export interface CollapseFormProps {
  firstName: string;
  lastName?: string;
  email: string;
  phone: string;
  isServiceInCart?: boolean;
  serviceData: ServiceData;
}

const CollapseForm: React.FC<CollapseFormProps> = ({
  firstName,
  lastName,
  email,
  phone,
  serviceData,
  isServiceInCart,
}) => {
  const formFieldLables =
    isServiceInCart && serviceData?.form
      ? {
          firstName: serviceData.form?.firstName?.label!,
          lastName: serviceData.form?.lastName?.label,
          email: serviceData.form?.email?.label!,
          phone: serviceData.form?.phone?.label!,
        }
      : undefined;

  const firstNameLabel = formFieldLables?.firstName;
  const lastNameLabel = lastName ? formFieldLables?.lastName : undefined;
  const emailLabel = formFieldLables?.email;
  const phoneLabel = formFieldLables?.phone;

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div>
        <Text
          data-hook={CollapseFormDataHooks.FIRST_NAME}
          type={TextType.Secondary}
        >
          {firstNameLabel}: {firstName}
        </Text>
      </div>
      {lastName && (
        <div>
          <Text
            data-hook={CollapseFormDataHooks.LAST_NAME}
            type={TextType.Secondary}
          >
            {lastNameLabel}: {lastName}
          </Text>
        </div>
      )}
      <div>
        <Text data-hook={CollapseFormDataHooks.EMAIL} type={TextType.Secondary}>
          {emailLabel}: {email}
        </Text>
      </div>
      <div>
        <Text data-hook={CollapseFormDataHooks.PHONE} type={TextType.Secondary}>
          {phoneLabel}: {phone}
        </Text>
      </div>
      <SectionNotification
        className={classes.sectionNotification}
        type={SectionNotificationType.default}
        size={SectionNotificationSize.compact}
      >
        <SectionNotification.Text>
          {t('app.booking-details-collapse-form-your-details-message')}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};

export default CollapseForm;
