import React from 'react';
import {
  TFunction,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { DurationDataHooks } from './dataHooks';
import { TagName } from '../../../../utils/tagName.const';
import {
  getCourseTotalNumberOfSessionsDurationText,
  getFormattedDuration,
} from '../../../../utils/duration/duration';
import Text, { TextType } from '../../Text/Text';
import { FormNestedSlot } from '../../../../types/formSelectedSlots';
import { ExperimentsConsts } from '../../../../consts/experiments';

export interface DurationProps {
  totalNumberOfSessions: number;
  slot: FormNestedSlot;
  isInPreviewMode?: boolean;
}

const getDummySlotDuration = (t: TFunction) => ({
  durationText: t('app.dummy-data.duration'),
  durationAriaText: t('app.dummy-data.duration'),
});

const Duration: React.FC<DurationProps> = ({
  totalNumberOfSessions,
  slot,
  isInPreviewMode,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { startDate, endDate } = slot;
  const duration =
    isInPreviewMode &&
    experiments.enabled(ExperimentsConsts.ShowDurationLabelInEditor)
      ? getDummySlotDuration(t)
      : getDuration({
          totalNumberOfSessions,
          startDate: startDate!,
          endDate: endDate!,
          t,
        });

  return (
    <Text
      type={TextType.Secondary}
      data-hook={DurationDataHooks.DURATION}
      tagName={TagName.Paragraph}
      aria-label={duration.durationAriaText}
    >
      {duration.durationText}
    </Text>
  );
};

const getDuration = ({
  totalNumberOfSessions,
  startDate,
  endDate,
  t,
}: {
  totalNumberOfSessions: number;
  startDate: string;
  endDate: string;
  t: TFunction;
}) => {
  return totalNumberOfSessions > 1
    ? getCourseTotalNumberOfSessionsDurationText(totalNumberOfSessions, t)
    : getFormattedDuration({
        startDate,
        endDate,
        t,
      });
};
export default Duration;
