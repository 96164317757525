import { LineItem } from '@wix/ambassador-ecom-v1-cart/types';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import {
  BusinessInfoBase,
  ServicePaymentDto,
  DynamicPriceInfo,
  MultiDynamicPriceInfo,
} from '@wix/bookings-uou-types';
import { DialogProps } from '../components/BookingsForm/Widget/Dialog/Dialog';
import { bookingsUouBookFlowBookingFormClickNextParams } from '@wix/bi-logger-wixboost-ugc/v2/types';
import { ActiveFeatures } from '@wix/bookings-uou-types/src';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  BookedLocation,
  BookedResource,
} from './ambassador/bookings/ambassador-bookings-v2-booking';

export enum TabsTranslationsKeys {
  Manage = 'app.settings.tabs.manage',
  Layout = 'app.settings.tabs.layout',
  Display = 'app.settings.tabs.display',
  Design = 'app.settings.tabs.design',
  Text = 'app.settings.tabs.text',
}

export enum TabsDataHooks {
  Manage = 'manage-tab-button',
  Layout = 'layout-tab-button',
  Display = 'display-tab-button',
  Design = 'design-tab-button',
  Text = 'text-tab-button',
  Support = 'support-tab-button',
  Premium = 'premium-tab-button',
}

export enum SettingsTab {
  Manage = 'manage',
  Layout = 'layout',
  Display = 'display',
  Design = 'design',
  Text = 'text',
  Support = 'support',
}

export enum PlatformIdentifiers {
  PRICING_PLAN_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3',
  PRICING_PLAN_SECTION_ID = 'membership_plan_picker_tpa',
  BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
  BOOKINGS_CHECKOUT_SECTION_ID = 'book_checkout',
  MEMBER_INFO_SECTION_ID = 'member_info',
  MEMBER_PAGE_SECTION_ID = 'member_page',
  BOOKINGS_SCHEDULER_SECTION_ID = 'scheduler',
  BOOKINGS_LIST_SECTION_ID = 'bookings_list',
  BOOKINGS_SERVICE_PAGE_SECTION_ID = 'Booking Service Page',
  BOOKINGS_CALENDAR_SECTION_ID = 'Booking Calendar',
  BOOKINGS_FORM_SECTION_ID = 'Booking Form',
  SANTA_MEMBERS_APP_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
  BOOKINGS_MEMBERS_AREA_WIDGET_ID = '14edb332-fdb9-2fe6-0fd1-e6293322b83b',
  MEMBERS_AREA_SECTION_ID = 'bookings_member_area',
  MEMBER_ACCOUNT_INFO_WIDGET_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb',
  MEMBER_AREA_PAGE = 'b976560c-3122-4351-878f-453f337b7245',
  ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd',
  ECOM_CART_SECTION_ID = 'shopping_cart',
}

export type SettingsSubTab = DesignTabSubSettings;

export enum DesignTabSubSettings {
  STYLES = 'styles',
  TEXT = 'text',
  BUTTON = 'button',
  INPUT_FIELD_STYLE = 'INPUT_FIELD_STYLE',
}

export enum AlignmentOptions {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum BorderStyle {
  UNDERLINE = 'line',
  BOX = 'box',
}

export enum PaymentMethod {
  MEMBERSHIP = 'membership',
  SINGLE = 'single',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export const enum ReservedPaymentOptionIds {
  SingleSession = 'SINGLE_SESSION_ID',
  BuyAPricingPlan = 'BUY_A_PRICING_PLAN_ID',
}

export const enum ReservedPaymentOptionLabels {
  ONLINE = 'PAY_ONLINE',
  OFFLINE = 'PAY_OFFLINE',
}

export enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
  TEMPLATE,
}

export interface BusinessInfo extends BusinessInfoBase {
  isSMSReminderEnabled: boolean;
  activeFeatures?: ActiveFeatures;
}

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export enum FormEvents {
  SETTINGS_TAB_CHANGED = 'settings_tab_changed',
  SETTINGS_SUB_TAB_SELECTED = 'settings_sub_tab_selected',
}

export enum PaymentType {
  SINGLE_SESSION = 'single_session',
  BUY_PLAN = 'buy_plan',
  USE_PLAN = 'use_plan',
}

export interface PlanWithDescription extends PublicPlan {
  description: string;
}

export type PaymentOption = {
  id: string;
  label: string;
  disabled?: boolean;
  validUntil?: string;
  suffix?: string;
  creditRemain?: number;
  benefitInfo?: BenefitWithPlanInfo;
  type: PaymentType;
  pricingDetails?: string;
};

export enum DialogType {
  OwnerSubmit = 'owner-form-submit',
}

export type Dialog = {
  type: DialogType;
  props: DialogProps;
};

export type ServicePaymentDetails = ServicePaymentDto;

export type SummaryPaymentDetails = {
  totalPrice: number;
  subtotalPerParticipant?: number;
  tax?: number;
  payNow?: number;
  payLater?: number;
};

export enum FieldLayout {
  SHORT = 'shorten',
  LONG = 'stretch',
}

export type ComplexPhone = {
  countryCode: string;
  phone: string;
  prefix: string;
};

export enum CartFlow {
  ADD_MORE_SESSIONS,
  CHECKOUT,
  CLOSE_MODAL,
}

export enum CartModalStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export type CartModal = {
  status: CartModalStatus;
  lineItems: LineItem[];
  biLoggerData?: bookingsUouBookFlowBookingFormClickNextParams;
};

export type PageAPIData = {
  serviceId?: string;
  timezone: string;
  slotAvailability?: SlotAvailability;
  dynamicPricePreSelection?: MultiDynamicPriceInfo;
};

export type IncompleteFormSelectedSlot = {
  timezone: string;
  openSpots?: number;
  totalSpots?: number;
  nestedSlots: IncompleteFormNestedSlot[];
};

export type IncompleteFormNestedSlot = {
  lineItemId: string;
  timezone: string;
  serviceId?: string;
  startDate?: string;
  endDate?: string;
  scheduleId?: string;
  sessionId?: string | null;
  groupId?: string | null;
  v2Availability?: boolean | null;
  location?: BookedLocation;
  resource?: BookedResource;
};

export type FormPageData = {
  timezone: string;
  formSelectedSlot: IncompleteFormSelectedSlot;
  dynamicPricePreSelection?: { [key: string]: DynamicPriceInfo };
};
